import Store from "@/store";
const PageUtils = {
    redirectCustomLoginPage() {
        // return console.log('redirectCustomLoginPage')
        const device = Store.getters.device
        if (device === 'desktop') {
            // console.log('desktop')
            location.href = "https://pc.mglobalcard.com/"
        } else {
            // console.log('mobile')
            location.href = "https://h5.mglobalcard.com/"
        }
    }
}

export default PageUtils;