<template>
    <div class="footer-block">
        <div class="wrapper fbr" style="">
            <div>
                <img src="@/assets/imgs/logo-small-hor.png" style="width: 54px;height: 31px;">
            </div>

            <div class="flex-row">
                <div class="menus" v-show="device === 'desktop'">
                    <div class="menu-item" @click="toPage('/payment')">{{ translateTitle('菜单项_支付') }}</div>
                    <div class="menu-item" @click="toPage('/transfer')">{{ translateTitle('菜单项_转账') }}</div>
                    <div class="menu-item" @click="toPage('/learnMore')">{{ translateTitle('菜单项_更多') }}</div>
                    <div class="menu-item" @click="toPage('/aboutUs')">{{ translateTitle('菜单项_关于我们') }} </div>
                    <!-- <div class="menu-item" @click="toPage('/download')">{{ translateTitle('菜单项_下载') }} </div> -->
                    <!-- <div class="menu-item ">{{ translateTitle('菜单项_卡片') }}</div> -->
                </div>
                <!-- <el-dropdown class="dropdown-menu" style="margin-right: 12px;" trigger="click">
                <span class="el-dropdown-link">
                    {{ language == 'EN' ? 'EN' : '简体中文' }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <div @click="changeLang('EN')">EN</div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="changeLang('CN')">简体中文</div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="contact-btn center-flex" @click="toPage('/contactUs')">
                {{ translateTitle('菜单项_联系我们') }}
            </div> -->
                <div @click="isSilderShow = true" v-show="device === 'mobile'">
                    <i class="el-icon-s-unfold" style="font-size: 24px;margin-right: 12px;color: #FFFFFF;"></i>
                </div>
            </div>
            <!-- <div class="flex-row" style="align-items: flex-start;" v-show="device === 'desktop'">
                <div class="menus">
                    <div class="menu-title">Payment</div>
                    <div class="menu-sub-title">Cards</div>
                </div>

                <div class="menus">
                    <div class="menu-title">Payment</div>
                    <div class="menu-sub-title">Send and Receive</div>
                    <div class="menu-sub-title">Send and Receive</div>
                </div>

                <div class="menus">
                    <div class="menu-title">Payment</div>
                    <div class="menu-sub-title">Secure Custodian</div>
                    <div class="menu-sub-title"></div>
                    <div class="menu-sub-title"></div>
                    <div class="menu-sub-title"></div>
                    <div class="menu-sub-title"></div>
                </div>
                <div class="menus">
                    <div class="menu-title">Payment</div>
                    <div class="menu-sub-title">FAQ</div>
                    <div class="menu-sub-title">Download App</div>
                    <div class="menu-sub-title">Terms&Conditions</div>
                    <div class="menu-sub-title">Privacy Policy</div>
                </div>
                <div class="menus">
                    <div class="menu-title">Payment</div>
                    <div class="menu-sub-title">Our Mission</div>
                </div>
            </div> -->
        </div>

        <div class="bottom-split" v-show="device === 'desktop'"></div>
        <el-drawer :visible.sync="isSilderShow" direction="rtl" @close="isSilderShow = false" :show-close="false"
            size="55%">
            <div class="mobile-menus">
                <div class="mobile-menu-item" @click="toPage('/')">{{ translateTitle('菜单项_首页') }}</div>
                <div class="mobile-menu-item" @click="toPage('/payment')">{{ translateTitle('菜单项_支付') }}</div>
                <div class="mobile-menu-item" @click="toPage('/transfer')">{{ translateTitle('菜单项_转账') }}</div>
                <div class="mobile-menu-item" @click="toPage('/learnMore')">{{ translateTitle('菜单项_更多') }}</div>
                <div class="mobile-menu-item" @click="toPage('/aboutUs')">{{ translateTitle('菜单项_关于我们') }}</div>
                <div class="mobile-menu-item">
                    <el-dropdown class="dropdown-menu" style="margin-right: 12px;"
                        trigger="click">
                        <span class="el-dropdown-link">
                            {{ language == 'EN' ? 'EN' : '简体中文' }}
                            <i class="el-icon-arrow-down el-icon--right">

                            </i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div @click="changeLang('EN')">EN</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="changeLang('CN')">简体中文</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- <div class="mobile-menu-item" @click="toPage('/download')">{{ translateTitle('菜单项_下载') }} </div> -->
                <!-- <div class="mobile-menu-item ">Cards</div> -->
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    name: 'FooterComp',
    components: {
    },
    // mixins: [ResizeMixin],

    computed: {
        device() {
            return this.$store.state.app.device
        },
        language() {
            return this.$store.state.settings.language
        }
    },
    // props: {
    //   arrayProp: {
    //     type: Array,
    //     required: false,
    //     default: function () {
    //       return []
    //     }
    //   },
    //   arrayProp: {
    //     type: Object,
    //     required: false,
    //     default: function () {
    //       return {}
    //     }
    //   }
    // },
    data() {
        const preData = {}
        return {
            message: 'Hello Vue!',

            isSilderShow: false
        };
    },
    watch: {
        message: {
            handler: function (newVal, oldVal) {

            },
            //立即监听
            immediate: true,
            //深度监听
            deep: false
        }
    },
    created() {
       // console.log('created: 组件实例创建完成');
    },
    mounted() {
        // console.log('mounted: 组件挂载到DOM之后');
    },
    methods: {

        toPage(path) {
            this.$router.push({
                path: path
            })
            this.isSilderShow = false
        },
        changeLang(val) {
            // this.changeLanguage(val)
            this.$store.dispatch('settings/changeLanguage', val)
            this.$i18n.locale = val;
        },
    },
    updated() {
        // console.log('updated: 组件数据更新之后');
    },
    beforeDestroy() {
        //console.log('beforeDestroy: 组件实例销毁之前');
    }
};
</script>
<style lang="scss" scoped>
.section {
    width: 100%;
}

.wrapper {
    padding: 33px 0;
    align-items: flex-start;
    box-sizing: border-box;
}

.footer-block {
    background: #000000;
}

// .menus {
//     margin: 0 30px;

//     .menu-title {
//         font-family: Montserrat, Montserrat;
//         font-weight: bold;
//         font-size: 14px;
//         color: #FFFFFF;
//         line-height: 18px;
//         text-align: left;
//         font-style: normal;
//     }

//     .menu-sub-title {
//         margin-top: 14px;
//         font-family: Montserrat, Montserrat;
//         font-weight: 400;
//         font-size: 11px;
//         color: #FFFFFF;
//         line-height: 15px;
//         text-align: left;
//         font-style: normal;
//     }
// }

.menus {
    // flex: 1;
    font-family: Bakbak One, Bakbak One;
    font-weight: 400;
    font-size: 11px;
    color: #3E3E3E;
    line-height: 17px;
    text-align: center;
    font-style: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .menu-item {
        // flex: 1;
        margin-right: 26px;
        width: 97px;
        height: 42px;
        font-family: Bakbak One, Bakbak One;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
        font-style: normal;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 37px 37px 37px 37px;
    }

    .menu-item.actived {
        background: #F9F9F9;
    }

    .menu-item:last-child {
        margin-right: 0px;
    }
}

.bottom-split {
    border-radius: 0px 0px 0px 0px;
    border-top: 1px solid rgba($color: #FFFFFF, $alpha: 0.5);
    height: 28px;
}

.mobile-menus {
    .mobile-menu-item {
        padding: 14px 22px;
        color: #333333;
        font-size: 14px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
    }
}

.dropdown-menu {
    margin-right: 28;
    color: #FFFFFF;
}

.contact-btn {
    width: 106px;
    height: 43px;
    background: #AC9455;
    border-radius: 36px 36px 36px 36px;
    padding: 7px 12px;
    font-family: Alata, Alata;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 30px;
    text-align: center;
    font-style: normal;
}

@media screen and (max-width: 1365px) {
    .wrapper {
        padding: 12px 24px;
        align-items: flex-start;
    }

    .contact-btn {
        width: auto;
        height: auto;
        background: #AC9455;
        border-radius: 36px 36px 36px 36px;
        padding: 7px 12px;
        font-family: Alata, Alata;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 1.15;
        text-align: center;
        font-style: normal;
        margin-right: 12px;
    }

    .el-dropdown-link {
        font-family: Bakbak One, Bakbak One;
        font-weight: 400;
        font-size: 16px;
        color: #3E3E3E;
        line-height: 24px;
        text-align: center;
        font-style: normal;
    }

    .el-icon-arrow-down {
        font-size: 17px;
    }
}
</style>