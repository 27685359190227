<template>
  <div class="section">
    <!-- <img src="@/assets/imgs/image-get-app-banner.png" class="banner-block"/> -->
    <SectionBlock :width="1125" :height="589" :mWidth="315" :mHeight="682"
      :bgImagePath="require('@/assets/imgs/image-get-app-banner.png')"
      :mobileBgImagePath="require('@/assets/imgs/bg-image-home-vertical.png')">
      <div class="wrapper get-app-wrapper" style="">
        <div class="banner-content">
          <div style="">
            <div class="title">
              {{ translateTitle('文本_首家颠覆全球金融生态的线上数字银行') }}
              <!-- FIRST ON-CHAIN DIGITAL BANK<br />
            REVOLUTIONIZING THE GLOBAL<br />
            FINANCIAL ECOSYSTEM<br /> -->
            </div>
            <div class="sub-title">
              {{ translateTitle('文案_首家颠覆全球金融生态的线上数字银行介绍') }}
            </div>
            <div class="get-app-btn" @click="$pu.redirectCustomLoginPage()">
              {{ translateTitle('文本_获取APP') }}
            </div>
          </div>
        </div>
      </div>
    </SectionBlock>
  </div>
</template>

<script>
// @ is an alias to /src
import SectionBlock from '@/components/ResSectionBlock.vue'
export default {
  name: 'HomeView',
  components: {
    SectionBlock
  }
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .banner-block {
    width: 100%
  }

  .get-app-wrapper {
    height: 100%;
    display: block;
    position: relative;
    padding-left: 219px;

  }

  .banner-content {
    width: 996px;
    margin: 0 auto;
    box-sizing: border-box;
    // p-top: 90px;

    position: absolute;
    bottom: 58px;

    // left: 0;
    // right: 0;
    .title {
      width: 557px;
      font-family: Montserrat, Montserrat;
      font-weight: bold;
      font-size: 33px;
      color: #FFFFFF;
      line-height: 47px;
      text-align: left;
      font-style: normal;
    }

    .sub-title {
      margin-top: 46px;
      width: 353px;
      font-family: Montserrat, Montserrat;
      font-weight: 800;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 23px;
      text-align: justify;
      font-style: normal;
      white-space: pre-line;
    }

    .get-app-btn {
      margin-top: 11px;
      width: 117px;
      height: 39px;
      background: #AC9455;
      border-radius: 30px 30px 30px 30px;

      font-family: Montserrat, Montserrat;
      font-weight: bold;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 14px;
      text-align: center;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}



@media screen and (max-width: 767px) {
  .get-app-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .banner-content {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    // margin: 0 auto;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    padding: 12px;

    .title {
      font-family: Montserrat, Montserrat;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 25px;
      text-align: center;
      font-style: normal;
      width: 238px;
      margin: 0 auto
    }

    .sub-title {
      margin-top: 12px;
      width: 281px;
      font-family: Inter, Inter;
      font-weight: 500;
      font-size: 11px;
      color: #FFFFFF;
      line-height: 14px;
      text-align: center;
      font-style: normal;
      white-space: pre-line;
    }

    .get-app-btn {
      width: 95px;
      height: auto;
      padding: 7px 12px;
      margin: 0 auto;
      margin-top: 18px;
      background: #AC9455;
      border-radius: 37px 37px 37px 37px;
      font-family: 'Montserrat', 'Montserrat';
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
      color: #FFFFFF;;
      text-align: center;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>