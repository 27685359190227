<template>
    <div class="section card-introduction-section" style="">
        <div class="wrapper">
            <div class="title">{{ translateTitle('文本_探索高度定制的卡解决方案') }}</div>
            <div class="desc-content">
                {{ translateTitle('文案_探索高度定制的卡解决方案') }}
            </div>
            <div>
                <el-tabs class="introductionTabPanel" v-model="activeName" @tab-click="handleClick"
                    tab-position="bottom">
                    <el-tab-pane :label="translateTitle('标签选项卡_虚拟卡')" name="vCard">
                        <div class="intro-vir-card">
                            <img src="@/assets/imgs/image-mul-cards.png">
                            <div style="width: 94px;"></div>
                            <div class="content">
                                <div class="rows">
                                    <div class="row">
                                        <div class="dot" />
                                        <div class="text">
                                            {{ translateTitle('文本_即时交易') }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="dot" />
                                        <div class="text">
                                            {{ translateTitle('文本_广受欢迎') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="get-card-btn" @click="$pu.redirectCustomLoginPage()">
                                    {{ translateTitle('文本_获取银行卡') }}
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="translateTitle('标签选项卡_实体卡')" name="pCard">
                        <div class="intro-vir-card">
                            <div class="content">
                                <div class="rows">
                                    <div class="row">
                                        <div class="dot" />
                                        <div class="text">
                                            {{ translateTitle('文本_即时交易') }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="dot" />
                                        <div class="text">
                                            {{ translateTitle('文本_广受欢迎') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="get-card-btn" @click="$pu.redirectCustomLoginPage()">
                                    {{ translateTitle('文本_获取银行卡') }}
                                </div>
                            </div>
                            <div style="width: 94px;"></div>
                            <img src="@/assets/imgs/image-mul-cards.png">
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="use-case-section">
                <div class="title" v-html="translateTitle('文本_面向员工供应商和客户的一体化支付卡')">
                    <!-- {{  }} -->
                    <!-- ALL-IN-ONE PAYMENT CARDS FOR YOUR <br /><span class="high-light">EMPLOYEES</span>, <span
                        class="high-light">VENDORS</span>, AND <span class="high-light">CUSTOMERS</span> -->
                </div>
                <div class="usage-cards">
                    <div class="usage-card">
                        <div class="card-header">
                            <img src="@/assets/imgs/card-usage/co-branded.png" />
                        </div>
                        <div class="card-body">
                            <div class="title">
                                {{ translateTitle('文本_联名卡计划') }}
                            </div>
                            <div class="content">
                                {{ translateTitle('文案_联名卡计划介绍') }}
                            </div>
                        </div>
                    </div>
                    <div class="usage-card">
                        <div class="card-header">
                            <img src="@/assets/imgs/card-usage/white-label-card.png" />
                        </div>
                        <div class="card-body">
                            <div class="title">
                                {{ translateTitle('文本_白标卡计划') }}
                            </div>
                            <div class="content">
                                {{ translateTitle('文案_白标卡计划介绍') }}
                            </div>
                        </div>
                    </div>
                    <div class="usage-card">
                        <div class="card-header">
                            <img src="@/assets/imgs/card-usage/card-issuing.png" />
                        </div>
                        <div class="card-body">
                            <div class="title">
                                {{ translateTitle('文本_发卡解决方案') }}
                            </div>
                            <div class="content">
                                {{ translateTitle('文案_发卡解决方案介绍') }}
                            </div>
                        </div>
                    </div>
                    <div class="usage-card">
                        <div class="card-header">
                            <img src="@/assets/imgs/card-usage/customizable-products.png" />
                        </div>
                        <div class="card-body">
                            <div class="title">
                                {{ translateTitle('文本_定制化产品') }}
                            </div>
                            <div class="content">
                                {{ translateTitle('文案_定制化产品介绍') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardIntroduction',
    components: {
    },
    // mixins: [ResizeMixin],
    computed: {
        // sidebar() {
        //   return this.$store.state.app.sidebar
        // }
    },
    // props: {
    //   arrayProp: {
    //     type: Array,
    //     required: false,
    //     default: function () {
    //       return []
    //     }
    //   },
    //   arrayProp: {
    //     type: Object,
    //     required: false,
    //     default: function () {
    //       return {}
    //     }
    //   }
    // },
    data() {
        const preData = {}
        return {
            message: 'Hello Vue!',
            activeName: "vCard"
        };
    },
    watch: {
        message: {
            handler: function (newVal, oldVal) {

            },
            //立即监听
            immediate: true,
            //深度监听
            deep: false
        }
    },
    created() {
        // console.log('created: 组件实例创建完成');
    },
    mounted() {
        // console.log('mounted: 组件挂载到DOM之后');
    },
    methods: {
        handleClick(e) {
            // console.log(e)
            this.activeName = e.name
        }
    },
    updated() {
        // console.log('updated: 组件数据更新之后');
    },
    beforeDestroy() {
        //console.log('beforeDestroy: 组件实例销毁之前');
    }
};
</script>
<style lang="scss" scoped>
.section {
    padding: 49px 0
}

.title {
    width: 100%;
    font-family: Inter, Inter;
    font-weight: normal;
    font-size: 12px;
    color: #101010;
    line-height: 16px;
    text-align: justified;
    font-style: 800;
    margin: 0 auto;
    font-family: Montserrat, Montserrat;
    width: 711px;
    font-weight: bold;
    font-size: 31px;
    color: #101840;
    line-height: 56px;
    text-align: center;
    font-style: normal;
}

.desc-content {
    width: 819px;
    margin: 0 auto;
    margin-top: 26px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 13px;
    color: #1D1B1B;
    line-height: 28px;
    text-align: justified;
    font-style: normal;
    width: 752px;
    height: 69px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 12px;
    color: #1D1B1B;
    line-height: 26px;
    text-align: justify;
    font-style: normal;
    text-transform: none;
}

::v-deep .el-tabs__header {
    display: flex;
    justify-content: center;
}

::v-deep .el-tabs__active-bar {
    background: #AC9455;
}

::v-deep .el-tabs__item {
    height: 30px;
    font-family: Montserrat, Montserrat;
    font-weight: 500;
    font-size: 17px;
    color: #A6A6A6;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}

::v-deep .el-tabs__item.is-active {
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 17px;
    color: #AC9455;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}

::v-deep .el-tabs__nav-wrap::after {
    background-color: transparent;
}

.introductionTabPanel {
    .el-tab-pane {
        // background: #101840;
        display: flex;
        justify-content: center;
    }
}

.intro-vir-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 49px;

    img {
        width: 311px;
        height: 332px;
    }

    .content {
        .rows {
            .row {
                display: flex;
                align-items: center;
                margin-bottom: 48px;

                .dot {
                    border-radius: 50%;
                    width: 11px;
                    height: 11px;
                    background: #AC9455;
                    border-radius: 11px 11px 11px 11px;
                }

                .text {
                    margin-left: 21px;
                    font-family: ABeeZee, ABeeZee;
                    font-weight: 400;
                    font-size: 23px;
                    color: #101840;
                    line-height: 30px;
                    text-align: left;
                    font-style: normal;
                }
            }

            .row:last-child {
                margin-bottom: 0;
            }
        }

        .get-card-btn {
            width: 142px;
            margin-top: 41px;
            padding: 15px 0px;
            background: #AC9455;
            border-radius: 37px 37px 37px 37px;
            font-family: Montserrat, Montserrat;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 17px;
            text-align: center;
            font-style: normal;
        }
    }
}

.use-case-section {
    margin-top: 68px;

    .title {
        width: 755px;
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 34px;
        color: #101840;
        line-height: 61px;
        text-align: center;
        font-style: normal;
        text-transform: none;


        .highlight {
            color: #AC9455;
        }
    }
}

.usage-cards {
    display: flex;
    width: 897px;
    height: 390px;
    margin: 0 auto;
    margin-top: 48px;
    overflow-x: scroll;
    padding-bottom: 49px;

    .usage-card {
        width: 263px;
        // height: 375px;
        background: #FFFFFF;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
        border-radius: 14px 14px 14px 14px;
        margin: 0 18px;
        box-sizing: border-box;

        .card-header {
            img {
                width: 263px;
                height: 204px;
                border-radius: 14px 14px 0 0;
            }
        }

        .card-body {
            padding: 0px 14px 14px 14px;

            .title {
                width: 100%;
                margin-top: 18px;
                font-family: Inter, Inter;
                font-weight: 800;
                font-size: 18px;
                color: #101010;
                line-height: 20px;
                text-align: center;
                font-style: bold;
            }

            .content {
                width: 219px;
                // height: 125px;
                font-family: Montserrat, Montserrat;
                font-weight: 500;
                font-size: 12px;
                color: #8D8D8D;
                line-height: 14px;
                text-align: left;
                font-style: normal;
                margin-top: 14px // margin: 14px auto 0 auto;
            }
        }
    }

    .usage-card:last-child {
        margin-right: 0;
    }
}

/*css主要部分的样式*/
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 6px;
    /*对垂直流动条有效*/
    height: 6px;
    /*对水平流动条有效*/
    // background: #AC9455;
    // border-radius: 4px 4px 4px 4px;
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #F2F4FC;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
    /* background-color: rosybrown; */
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #DDDEE0;
    width: 179px;
    height: 6px;
    background: #AC9455;
    border-radius: 4px 4px 4px 4px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
}

/*定义滑块悬停变化颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb:hover {
    background-color: #C7C9CC;
    background: #AC9455;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
    /* background-color: cyan; */
}

/*定义右下角汇合处的样式*/
::-webkit-scrollbar-corner {
    /* background: khaki; */
}

/* 隐藏滚动条 */
/* ::-webkit-scrollbar {
    display: none;
} */

@media screen and (max-width: 767px) {
    .section {
        padding: 24px;
    }

    .card-introduction-section {
        width: unset;
    }

    .title {
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 16px;
        color: #101840;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        width: unset;
    }

    .desc-content {
        width: unset;
        margin: 0 14px;
        font-family: Inter, Inter;
        font-weight: 500;
        font-size: 11px;
        color: #757B8A;
        line-height: 14px;
        text-align: justified;
        font-style: normal;
        margin-top: 14px;

        p {
            text-align: justify;
        }
    }

    ::v-deep .el-tabs__header.is-bottom {
        display: flex;
        justify-content: center;
        margin-top: 31px;
    }

    ::v-deep .el-tabs__active-bar {
        background: #AC9455;
    }

    ::v-deep .el-tabs__item {
        height: 30px;
        font-family: Montserrat, Montserrat;
        font-weight: 500;
        font-size: 17px;
        color: #A6A6A6;
        line-height: 22px;
        text-align: left;
        font-style: normal;
    }

    ::v-deep .el-tabs__item.is-active {
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 17px;
        color: #AC9455;
        line-height: 22px;
        text-align: left;
        font-style: normal;
    }

    ::v-deep .el-tabs__nav-wrap::after {
        background-color: transparent;
    }

    .introductionTabPanel {
        margin-top: 31px;

        .el-tab-pane {
            // background: #101840;
            display: flex;
            justify-content: center;
        }
    }

    .intro-vir-card {
        display: block;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;
        padding-top: 49px;

        img {
            width: 100%;
            // height: 332px;
        }

        .content {
            .rows {
                .row {
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;

                    .dot {
                        border-radius: 50%;
                        width: 11px;
                        height: 11px;
                        background: #AC9455;
                        border-radius: 11px 11px 11px 11px;
                    }

                    .text {
                        margin-left: 12px;
                        font-family: ABeeZee, ABeeZee;
                        font-weight: 400;
                        font-size: 14px;
                        color: #101840;
                        line-height: 18px;
                        text-align: left;
                        font-style: normal;
                    }
                }

                .row:last-child {
                    margin-bottom: 0;
                }
            }

            .get-card-btn {
                margin: 24px auto;
                width: 100px;
                // margin-top: 24px;
                padding: 7px 14px;
                background: #AC9455;
                border-radius: 37px 37px 37px 37px;
                font-family: Montserrat, Montserrat;
                font-weight: bold;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 1.2;
                text-align: center;
                font-style: normal;
            }
        }
    }

    .use-case-section {
        // margin-top: 68px;
        margin-top: 24px;

        .title {
            width: 100%;
            // width: 721px;
            font-family: Montserrat, Montserrat;
            font-weight: bold;
            font-size: 12px;
            color: #101840;
            line-height: 19px;
            text-align: center;
            font-style: normal;
            text-transform: none;

            .high-light {
                color: #AC9455;
            }
        }
    }

    .usage-cards {
        display: flex;
        width: 100%;
        height: 390px;
        margin: 0 auto;
        margin-top: 48px;
        overflow-x: scroll;
        padding-bottom: 49px;

        .usage-card {
            width: 100%;
            height: 375px;
            background: #FFFFFF;
            box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
            border-radius: 14px 14px 14px 14px;
            margin: 0 18px;

            .card-header {
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 14px 14px 0 0;
                }
            }

            .card-body {
                padding: 0 12px;

                .title {
                    margin-top: 18px;
                    font-family: Inter, Inter;
                    font-weight: normal;
                    font-size: 14px;
                    color: #101010;
                    line-height: 20px;
                    text-align: center;
                    font-style: normal;
                }

                .content {
                    width: 219px;
                    height: 125px;
                    font-family: Montserrat, Montserrat;
                    font-weight: 400;
                    font-size: 11px;
                    color: #8D8D8D;
                    line-height: 16px;
                    text-align: left;
                    font-style: normal;
                    margin: 14px auto 0 auto;
                }
            }
        }

        .usage-card:last-child {
            margin-right: 18px;
        }
    }

    /*css主要部分的样式*/
    /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
    ::-webkit-scrollbar {
        width: 6px;
        /*对垂直流动条有效*/
        height: 6px;
        /*对水平流动条有效*/
        // background: #AC9455;
        // border-radius: 4px 4px 4px 4px;
    }

    /*定义滚动条的轨道颜色、内阴影及圆角*/
    ::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: #F2F4FC;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
        /* background-color: rosybrown; */
    }

    /*定义滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #DDDEE0;
        // width: 179px;
        height: 6px;
        background: #AC9455;
        border-radius: 4px 4px 4px 4px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
    }

    /*定义滑块悬停变化颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb:hover {
        background-color: #C7C9CC;
        background: #AC9455;
    }

    /*定义两端按钮的样式*/
    ::-webkit-scrollbar-button {
        /* background-color: cyan; */
    }

    /*定义右下角汇合处的样式*/
    ::-webkit-scrollbar-corner {
        /* background: khaki; */
    }

    /* 隐藏滚动条 */
    ::-webkit-scrollbar {
        display: none;
    }
}
</style>