import Vue from 'vue'
import store from '@/store'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang导入Element的语言包 英文
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang g导入Element的语言包 中文
import enLocale from './en' // 导入项目中用到的英文语言包
import zhLocale from './zh'// 导入项目中用到的中文语言包
Vue.use(VueI18n)
const messages = {
  EN: {
    ...enLocale,
    ...elementEnLocale
  },
  CN: {
    ...zhLocale,
    ...elementZhLocale,
  },
 
}
 
const i18n = new VueI18n({
  locale: store.getters['settings/language'] || 'CN', // 设置语种
  messages, // 设置全局当地语言包,
  fallbackLocale: 'EN',
  silentTranslationWarn: true,
})
 
export default i18n