<template>
    <div>
        <div v-if="device == 'desktop'"
            :style="{ width: bWidth + 'px', height: bHeight + 'px', backgroundImage: 'url(' + bgImagePath + ')' }"
            style="margin: 0 auto;" class="bg-div">
            <slot></slot>
        </div>
        <div v-else
            :style="{ width: bWidth + 'px', height: bHeight + 'px', backgroundImage: 'url(' + mobileBgImagePath + ')' }"
            style="margin: 0 auto;" class="bg-div">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const WIDTH = 992 // refer to Bootstrap's responsive design
const { body } = document
export default {
    name: 'SectionBlock',
    components: {
    },
    // mixins: [ResizeMixin],
    computed: {
        // ...mapGetters([
        //     'sidebar',
        //     'device'
        // ])
        device() {
            return this.$store.state.app.device
        }
    },
    props: {
        mWidth: {
            type: Number,
            default: 992
        },
        mHeight: {
            type: Number,
            default: 782
        },
        width: {
            type: Number,
            default: 1125
        },
        minWidth: {
            type: Number,
            default: 1125
        },
        height: {
            type: Number,
            default: 1006
        },
        mobileBgImagePath: {
            type: String,
            default: ''
        },
        bgImagePath: {
            type: String,
            default: ''
        },
        arrayProp: {
            type: Array,
            required: false,
            default: function () {
                return []
            }
        },
        arrayProp: {
            type: Object,
            required: false,
            default: function () {
                return {}
            }
        }
    },
    data() {
        const preData = {}
        return {
            bWidth: 0,
            bHeight: 0
        };
    },
    watch: {
        message: {
            handler: function (newVal, oldVal) {

            },
            //立即监听
            immediate: true,
            //深度监听
            deep: false
        }
    },
    created() {
       // console.log('created: 组件实例创建完成');
    },
    beforeMount() {
        window.addEventListener('resize', this.$_resizeHandler)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.$_resizeHandler)
    },
    mounted() {
        // console.log('mounted: 组件挂载到DOM之后');
        this.resizeCallback()
    },
    methods: {
        $_resizeHandler() {
            this.resizeCallback()
        },
        resizeCallback() {
            // console.log("this.device: " + this.device)
            const isMobile = this.isMobile()
            if (!isMobile) {
                this.bWidth = document.body.clientWidth
                if (this.bWidth > this.width) {
                    this.bWidth = this.width
                }
                if (this.bWidth < this.minWidth) {
                    this.bWidth = this.minWidth
                }
                this.bHeight = parseInt(this.height * this.bWidth / this.width)
            } else {
                this.bWidth = document.body.clientWidth
                this.bHeight = parseInt(this.mHeight * this.bWidth / this.mWidth)
            }
        },
        isMobile() {
            const rect = body.getBoundingClientRect()
            return rect.width - 1 < WIDTH
        },
    },
    updated() {
        // console.log('updated: 组件数据更新之后');
    },
    beforeDestroy() {
        //console.log('beforeDestroy: 组件实例销毁之前');
    }
};
</script>
<style lang="scss" scoped>
.bg-div {
    // background-image: url('~@/assets/imgs/image-get-app-banner.png');
    background-size: 100% 100%;
}
</style>