export default {
  vabI18n: {
    菜单项_首页: "首页",
    菜单项_支付: "支付",
    菜单项_转账: "转账",
    菜单项_更多: "更多",
    菜单项_关于我们: "关于我们",
    菜单项_联系我们: "联系我们",
    菜单项_卡片: "卡片",
    菜单项_下载: "下载",
    文本_首家颠覆全球金融生态的线上数字银行: "首家颠覆全球金融生态的线上数字银行",
    文案_首家颠覆全球金融生态的线上数字银行介绍: "我们的平台无缝融合加密货币与传统理财，打破加密货币与法定货币兑换的壁垒。在一个地方体验快速全球支付和全面金融解决方案。",
    文本_获取APP: "获取APP",
    文本_探索高度定制的卡解决方案: "探索高度定制的卡解决方案",
    文案_探索高度定制的卡解决方案: "通过MW Card增强您的金融运营，MW Card是一款多功能、可定制的嵌入式卡程序。MW Card使您能够超越传统支付流程，开启新的收入机会，并使您的支付体验现代化，提供更大的灵活性、控制力和移动性。",
    文本_即时交易: "即时交易",
    文本_广受欢迎: "广受欢迎",
    文本_获取银行卡: "获取银行卡",
    标签选项卡_虚拟卡: "虚拟卡",
    标签选项卡_实体卡: "实体卡",
    文本_面向员工供应商和客户的一体化支付卡: "面向员工、供应商和客户的一体化支付卡",
    文本_联名卡计划: "联名卡计划",
    文案_联名卡计划介绍: "在短短几周内为您的公司或客户发行品牌实体卡和虚拟卡，并在预先批准的卡设计上印有您的标识",
    文本_白标卡计划: "白标卡计划",
    文案_白标卡计划介绍: "一套功能齐全的卡片程序，允许您使用自己的卡片、门户网站和移动应用程序启动。根据您的需求定制费用表。",
    文本_发卡解决方案: "发卡解决方案",
    文案_发卡解决方案介绍: "管理整个发卡流程，全面整合卡管理生命周期。受益于强大的交易和收入报告功能，实现全面的监督和控制。",
    文本_定制化产品: "定制化产品",
    文案_定制化产品介绍: "管理整个发卡流程，全面整合卡管理生命周期。受益于强大的交易和收入报告功能，实现全面的监督和控制。",
    文本_放开分散支付的权力: "放开分散支付的权力",
    文本_非托管资产卡与IBAN: "非托管资产卡与IBAN",
    文本_你的财富由你控制: "你的财富由你控制",
    文本_了解更多: "了解更多",
    文本_标题_用例: "用例",
    标签选项卡_消费者: "消费者",
    标签选项卡_工资单: "工资单",
    标签选项卡_多币种: "多币种",
    标签选项卡_旅行: "旅行",
    标签选项卡_学生: "学生",
    文本_为用户提供预付卡: "为用户提供预付卡",
    文案_为用户提供预付卡介绍: "通过向客户提供品牌预付卡来提升业务。提高忠诚度，为您的业务开启新的强大收入来源。",
    文本_在公司工资卡上支付工资: "在公司工资卡上支付工资",
    文案_在公司工资卡上支付工资介绍: "使用我们的品牌卡简化您的工资并节省成本。就连工资支票现在也可以直接记入信用卡。",
    文本_一卡多币: "一卡多币",
    文案_一卡多币介绍: "我们的多币种预付卡和交易时的自动兑换提供了最佳价值。你也可以从外汇中赚取收入。",
    文本_随时随地轻松支付: "随时随地轻松支付",
    文案_随时随地轻松支付介绍: "预付旅游卡是现金和旅行支票的便捷替代品。在世界各地的时区之间安全交易。",
    文本_面向年轻一代的预付卡解决方案: "面向年轻一代的预付卡解决方案",
    文案_面向年轻一代的预付卡解决方案介绍: "预付卡为学生提供了一个简单的解决方案，用于管理从父母或工作中收到的资金，并将其用于教育费用和Pos/电商交易。",

    文本_重新定义支付体验: "重新定义支付体验",

    文本_支付体验_自托管: "自托管",
    文案_支付体验_自托管介绍: "用户是资产托管人，管理和控制自己的资产",
    文本_支付体验_快捷方便: "快捷方便",
    文案_支付体验_快捷方便介绍: "5分钟内全球双向结算",
    文本_支付体验_可信安全: "可信安全",
    文案_支付体验_可信安全介绍: "符合顶级PCI-DSS安全标准",
    文本_支付体验_灵活性: "灵活性",
    文案_支付体验_灵活性介绍: "轻松管理，随时存取款",

    文本_MW_VVIP钛资产卡象征尊严: "MW VVIP钛资产卡象征尊严",
    文案_MW_VVIP钛资产卡象征尊严介绍: "MW VVIP钛资产卡——专为体现财富、提升尊贵地位而设计。",

    文本_VVIP卡尊严_亚太地区唯一代表: "亚太地区唯一代表",
    文案_VVIP卡尊严_亚太地区唯一代表介绍: "MW Global是唯一一家合作发行万事达卡资产卡的公司",
    文本_VVIP卡尊严_全球可用: "全球可用",
    文案_VVIP卡尊严_全球可用介绍: "您可以放心地在世界各地的商家、餐馆和服务提供商处使用它，享受无缝的支付体验。",
    文本_VVIP卡尊严_享受交易: "享受交易",
    文案_VVIP卡尊严_享受交易介绍: "MW资产卡带给您真正的消费自由，让您体验真正无限的支付自由。",
    文本_VVIP卡尊严_PINGO是跨境电商平台的首选: "PINGO是跨境电商平台的首选",
    文案_VVIP卡尊严_PINGO是跨境电商平台的首选介绍: "VVIP可享受高达10%的购物积分折扣",

    标题_常见问题: "常见问题",
    副标题_将法币与您的加密货币转移给世界上任何地方的任何人: "将法币与您的加密货币转移给世界上任何地方的任何人",
    标题_机会: "机会",
    副标题_机会介绍: "消费数字货币的终极解决方案。使用MW，您可以享受无缝交易，并获得高达8%的数字货币投资年回报率，并保持控制您的卡的安全性。",
    文本_加入MW: "加入MW",

    文本_首页_常见问题_MW是否支持加密货币存款: "MW是否支持加密货币存款?",
    文本_首页_常见问题_MW是否支持加密货币存款_回答: "MW支持USDT、BTC、ETH的存款。用户可根据自身需求和消费情况随时随地充值，整个充值过程不超过10分钟;MW将在第二阶段向更多的加密货币开放。",

    标题_您的一体化加密支付解决方案: "您的一体化加密支付解决方案",
    文本_可访问的加密钱包每个人无处不在让我们通过MWGlobal拥抱加密货币支付的未来: "可访问的加密钱包每个人无处不在让我们通过MWGlobal拥抱加密货币支付的未来",
    文本_立即办卡: "立即办卡",
    标题_像法币一样使用加密货币: "像法币一样使用加密货币",
    文本_无缝管理你的日常开支: "无缝管理你的日常开支",
    标题_虚拟卡: "虚拟卡",
    文本_极具竞争力: "极具竞争力",
    文本_即时执行: "即时执行",
    标题_实体卡: "实体卡",
    文本_点击支付: "点击支付",
    文本_实时交易: "实时交易",
    文本_自动取款机取款: "自动取款机取款",
    标题_随处使用加密货币: "随处使用加密货币",
    文本_将法币与您的加密货币转移给世界上任何地方的任何人: "将法币与您的加密货币转移给世界上任何地方的任何人",
    标题_我们的产品公平的利率没有隐藏费用: "我们的产品公平的利率没有隐藏费用",
    文本_发现每个计划的申请费和充值费找到你的完美匹配: "发现每个计划的申请费和充值费找到你的完美匹配",
    文本_实体卡费用: "实体卡费用",
    文本_虚拟卡费用: "虚拟卡费用",
    了解更多: "了解更多",
    文本_世界是你的: "世界是你的",
    文本_MWGLOBAL数字银行多年来运营良好接受全球企业和用户托管资金33亿美元托管资金在全球数字银行中排名第一: "MWGLOBAL数字银行多年来运营良好接受全球企业和用户托管资金33亿美元托管资金在全球数字银行中排名第一",
    文本_你的财产在我们这里很安全: "你的财产在我们这里很安全",
    文本_连接钱包: "连接钱包",
    文本_托管资金用于币安等世界知名平台利润返还给用户月平均收益率: "托管资金用于币安等世界知名平台利润返还给用户月平均收益率为15%~18%。",

    文本_在舒适的家中体验无与伦比的数字便利: "在舒适的家中体验无与伦比的数字便利",
    文本_发现为您的旅行餐饮网上购物和日常需求量身定制的独家优惠通过无缝的数字解决方案来改善您的生活方式节省时间金钱和精力: "发现为您的旅行餐饮网上购物和日常需求量身定制的独家优惠通过无缝的数字解决方案来改善您的生活方式节省时间金钱和精力",
    文本_为互联世界提供无缝传输: "为互联世界提供无缝传输",
    文本_轻松管理内部本地和国际银行转账体验安全高效的交易让您与全球保持联系: "轻松管理内部本地和国际银行转账体验安全高效的交易让您与全球保持联系",
    文本_全大写_本地银行转帐: "本地银行转帐",
    文本_全大写_内部转账: "内部转账",
    文本_全大写_国际银行转帐: "国际银行转帐",
    文本_即将到来: "即将到来",
    文本_全大写_快速安全实惠即时接收转账: "快速安全实惠即时接收转账",
    文本_即时以低费用接收全球转账确保快速和成本有效的交易: "即时以低费用接收全球转账确保快速和成本有效的交易",

    文本_全大写_通过分散的解决方案和无缝的数字资产增长来释放全球金融: "通过分散的解决方案和无缝的数字资产增长来释放全球金融",
    文本_分散的金融: "分散的金融",
    文本_数字资产增长: "数字资产增长",
    文本_全球跨境支付与结算: "全球跨境支付与结算",
    文本_数字法定货币结算: "数字法定货币结算",

    我们正在让每个人都能使用加密货币: "我们正在让每个人都能使用加密货币",
    我们的愿景: "我们的愿景",
    我们致力于改变人们的支付方式通过区块链技术和数字资产我们正在构建一个数字资产支付基础设施重点是促进更大的金融包容性和加密货币的经济机会: "我们致力于改变人们的支付方式。通过区块链技术和数字资产，我们正在构建一个数字资产支付基础设施，重点是促进更大的金融包容性和加密货币的经济机会。",
    加密先锋: "加密先锋",
    实现加密货币与法定货币的无缝交易实现Web30的无现金时代: "实现加密货币与法定货币的无缝交易，实现Web3.0的无现金时代。",
    数字支付革命: "数字支付革命",
    我们的目标是将加密货币的效用传播给每个人因为我们相信支付的未来在于数字化我们很自豪能够使这一未来成为现实: "我们的目标是将加密货币的效用传播给每个人，因为我们相信支付的未来在于数字化，我们很自豪能够使这一未来成为现实",
    价值: "价值",
    与合作伙伴共同构建创新透明安全高效的支付系统: "与合作伙伴共同构建创新、透明、安全、高效的支付系统。",

    文本_与合作伙伴共同构建创新透明安全高效的支付系统: "与合作伙伴共同构建创新、透明、安全、高效的支付系统。",
    文本_想成为我们的合作伙伴吗: "想成为我们的合作伙伴吗?",
    文本_请给我们一些你自己的详情我们会很快联系: "请给我们一些你自己的详情，我们会很快联系!",
    文本_对我们的产品有问题吗: "对我们的产品有问题吗?",
    文本_网站待定: "网站待定",
    文本_电子邮件地址待定: "电子邮件地址待定",
    文本_联系我们表单_接受新品提醒: "如果您不希望收到营销和促销材料，请在方框中打勾。",
    文本_提交: "提交",

    文本_表单_联系我们_字段名_姓: "姓",
    文本_表单_联系我们_字段名_名: "名",
    文本_表单_联系我们_字段名_电子邮箱: "电子邮箱",
    文本_表单_联系我们_字段名_手机号码: "手机号码",
    文本_表单_联系我们_字段名_公司名称: "公司名称",
    文本_表单_联系我们_字段名_公司网站: "公司网站",
    文本_表单_联系我们_字段名_合作类型: "合作类型",
    文本_表单_联系我们_字段名_留言: "留言",
    文本_表单_联系我们_输入提示_姓: "请输入姓氏",
    文本_表单_联系我们_输入提示_名: "请输入名字",
    文本_表单_联系我们_输入提示_电子邮箱: "请输入电子邮箱",
    文本_表单_联系我们_输入提示_手机号码: "请输入手机号码",
    文本_表单_联系我们_输入提示_公司名称: "请输入公司名称",
    文本_表单_联系我们_输入提示_公司网站: "请输入公司网站",
    文本_表单_联系我们_输入提示_合作类型: "请输入合作类型",
    文本_表单_联系我们_输入提示_留言: "请输入留言",
    提示_表单_联系我们_提交成功: "提交成功，我们会尽快联系你",
    
    文本_现在开始您的加密支付之旅与mw全球:"现在开始您的加密支付之旅与mw全球",
    文本_下载页_下载APP:"下载APP",
    文本_下载页_获取:"获取",

    文本_文本内容: "文本内容",
    文案_文本内容介绍: "文案内容",
  },
}
