<template>
    <div class="section" style="">
        <div class="wrapper">
            <div class="section-title">
                {{ translateTitle('文本_标题_用例') }}
            </div>
            <div class="use-case-panel">
                <div class="list">
                    <div class="list-item" :class="caseActiveName == 'CONSUMERS' ? 'actived' : ''"
                        @click="onCaseItemClick('CONSUMERS')">
                        <img class="icon" src="@/assets/imgs/use-case-icon/consumers.png" />
                        <div class="label">
                            {{ translateTitle('标签选项卡_消费者') }}
                        </div>
                    </div>
                    <div class="list-item" :class="caseActiveName == 'PAYROLL' ? 'actived' : ''"
                        @click="onCaseItemClick('PAYROLL')">
                        <img class="icon" src="@/assets/imgs/use-case-icon/payroll.png" />
                        <div class="label">

                            {{ translateTitle('标签选项卡_工资单') }}
                        </div>
                    </div>
                    <div class="list-item" :class="caseActiveName == 'MULTI-CURRENCIES' ? 'actived' : ''"
                        @click="onCaseItemClick('MULTI-CURRENCIES')">
                        <img class="icon" src="@/assets/imgs/use-case-icon/multi-currency.png" />
                        <div class="label">
                            {{ translateTitle('标签选项卡_多币种') }}
                        </div>
                    </div>
                    <div class="list-item" :class="caseActiveName == 'TRAVEL' ? 'actived' : ''"
                        @click="onCaseItemClick('TRAVEL')">
                        <img class="icon" src="@/assets/imgs/use-case-icon/travel.png" />
                        <div class="label">
                            {{ translateTitle('标签选项卡_旅行') }}
                        </div>
                    </div>
                    <div class="list-item" :class="caseActiveName == 'STUDENT' ? 'actived' : ''"
                        @click="onCaseItemClick('STUDENT')">
                        <img class="icon" src="@/assets/imgs/use-case-icon/student.png" />
                        <div class="label">
                            {{ translateTitle('标签选项卡_学生') }}
                        </div>
                    </div>
                </div>
                <div class="details" v-show="caseActiveName == 'CONSUMERS'">
                    <div class="title">
                        {{ translateTitle('文本_为用户提供预付卡') }}
                    </div>
                    <div class="desc">
                        {{ translateTitle('文案_为用户提供预付卡介绍') }}
                    </div>
                    <img class="image" src="@/assets/imgs/use-case/image-prepare-cards.png" />
                </div>
                <div class="details" v-show="caseActiveName == 'PAYROLL'">
                    <div class="title">
                        {{ translateTitle('文本_在公司工资卡上支付工资') }}
                    </div>
                    <div class="desc">
                        {{ translateTitle('文案_在公司工资卡上支付工资介绍') }}
                    </div>
                    <img class="image" src="@/assets/imgs/use-case/image-payroll.png" />
                </div>
                <div class="details" v-show="caseActiveName == 'MULTI-CURRENCIES'">
                    <div class="title">
                        {{ translateTitle('文本_一卡多币') }}
                    </div>
                    <div class="desc">
                        {{ translateTitle('文案_一卡多币介绍') }}
                    </div>
                    <img class="image" src="@/assets/imgs/use-case/multi-currencies.png" />
                </div>
                <div class="details" v-show="caseActiveName == 'TRAVEL'">
                    <div class="title">
                        {{ translateTitle('文本_随时随地轻松支付') }}
                    </div>
                    <div class="desc">
                        {{ translateTitle('文案_随时随地轻松支付介绍') }}
                    </div>
                    <img class="image" src="@/assets/imgs/use-case/travel.png" />
                </div>
                <div class="details" v-show="caseActiveName == 'STUDENT'">
                    <div class="title">
                        {{ translateTitle('文本_面向年轻一代的预付卡解决方案') }}
                    </div>
                    <div class="desc">
                        {{ translateTitle('文案_面向年轻一代的预付卡解决方案介绍') }}
                    </div>
                    <img class="image" src="@/assets/imgs/use-case/student.png" />
                </div>
            </div>
            <div class="payment-experience-wrapper">
                <div class="section-title" style="">
                    {{ translateTitle('文本_重新定义支付体验') }}
                </div>
                <div class="experience-cards-warpper" style="">
                    <img class="arrow-btn arrow-left-btn" src="@/assets/imgs/white-bg-left-arrow.png"
                        @click="prevCarousel" v-if="device === 'desktop'" />
                    <div class="experience-cards">
                        <div class="block">
                            <el-carousel height="221px" arrow="never" indicator-position="none" :autoplay="false"
                                ref="paymentExoerienceCarousel" v-if="device === 'desktop'">
                                <el-carousel-item>
                                    <div class="experience-card-item">
                                        <img class="icon" src="@/assets/imgs/payment-experience-icons/self-host.png" />
                                        <div class="title">
                                            {{ translateTitle('文本_支付体验_自托管') }}
                                        </div>
                                        <div class="desc">
                                            {{ translateTitle('文案_支付体验_自托管介绍') }}
                                        </div>
                                    </div>

                                    <div class="experience-card-item">
                                        <img class="icon" src="@/assets/imgs/payment-experience-icons/fast.png" />
                                        <div class="title">
                                            {{ translateTitle('文本_支付体验_快捷方便') }}
                                        </div>
                                        <div class="desc">
                                            {{ translateTitle('文案_支付体验_快捷方便介绍') }}
                                        </div>
                                    </div>
                                    <div class="experience-card-item">
                                        <img class="icon" src="@/assets/imgs/payment-experience-icons/trusted.png" />
                                        <div class="title">
                                            {{ translateTitle('文本_支付体验_可信安全') }}
                                        </div>
                                        <div class="desc">
                                            {{ translateTitle('文案_支付体验_可信安全介绍') }}
                                        </div>
                                    </div>
                                </el-carousel-item>

                                <el-carousel-item>
                                    <div class="experience-card-item">
                                        <img class="icon" src="@/assets/imgs/payment-experience-icons/flexiblity.png" />
                                        <div class="title">
                                            {{ translateTitle('文本_支付体验_灵活性') }}
                                        </div>
                                        <div class="desc">
                                            {{ translateTitle('文案_支付体验_灵活性介绍') }}
                                        </div>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                            <div class="experience-cards-panel" v-else>
                                <div style="width: 50%;" class="center-flex">
                                    <div class="experience-card-item">
                                        <img class="icon" src="@/assets/imgs/payment-experience-icons/self-host.png" />
                                        <div class="title">
                                            {{ translateTitle('文本_支付体验_自托管') }}
                                        </div>
                                        <div class="desc">
                                            {{ translateTitle('文案_支付体验_自托管介绍') }}
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 50%;" class="center-flex">
                                    <div class="experience-card-item">
                                        <img class="icon" src="@/assets/imgs/payment-experience-icons/fast.png" />
                                        <div class="title">
                                            {{ translateTitle('文本_支付体验_快捷方便') }}
                                        </div>
                                        <div class="desc">
                                            {{ translateTitle('文案_支付体验_快捷方便介绍') }}
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 50%;" class="center-flex">
                                    <div class="experience-card-item">
                                        <img class="icon" src="@/assets/imgs/payment-experience-icons/trusted.png" />
                                        <div class="title">
                                            {{ translateTitle('文本_支付体验_可信安全') }}
                                        </div>
                                        <div class="desc">
                                            {{ translateTitle('文案_支付体验_可信安全介绍') }}
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 50%;" class="center-flex">
                                    <div class="experience-card-item">
                                        <img class="icon" src="@/assets/imgs/payment-experience-icons/flexiblity.png" />
                                        <div class="title">
                                            {{ translateTitle('文本_支付体验_灵活性') }}
                                        </div>
                                        <div class="desc">
                                            {{ translateTitle('文案_支付体验_灵活性介绍') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <img class="arrow-btn arrow-right-btn" src="@/assets/imgs/white-bg-right-arrow.png"
                        @click="nextCarousel" v-if="device === 'desktop'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'UseCaseComp',
    components: {
    },
    // mixins: [ResizeMixin],
    computed: {
        ...mapGetters([
            'sidebar',
            'device',
            'language'
        ])
    },
    // props: {
    //   arrayProp: {
    //     type: Array,
    //     required: false,
    //     default: function () {
    //       return []
    //     }
    //   },
    //   arrayProp: {
    //     type: Object,
    //     required: false,
    //     default: function () {
    //       return {}
    //     }
    //   }
    // },
    data() {
        const preData = {}
        return {
            message: 'Hello Vue!',
            caseActiveName: "CONSUMERS"
        };
    },
    watch: {
        message: {
            handler: function (newVal, oldVal) {

            },
            //立即监听
            immediate: true,
            //深度监听
            deep: false
        }
    },
    created() {
       // console.log('created: 组件实例创建完成');
    },
    mounted() {
        // console.log('mounted: 组件挂载到DOM之后');
    },
    methods: {
        nextCarousel() {
            this.$refs.paymentExoerienceCarousel.next()
        },
        prevCarousel() {
            this.$refs.paymentExoerienceCarousel.prev()
        },
        onCaseItemClick(caseName) {
            this.caseActiveName = caseName
        }
    },
    updated() {
        // console.log('updated: 组件数据更新之后');
    },
    beforeDestroy() {
        //console.log('beforeDestroy: 组件实例销毁之前');
    }
};
</script>
<style lang="scss" scoped>
.section {
    padding-top: 52px;
    padding-bottom: 42px;
}

.section-title {
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 28px;
    color: #101840;
    line-height: 37px;
    text-align: center;
    // margin-top: 98px;
}

.use-case-panel {
    margin-top: 44px;
    display: flex;

    .list {
        width: 208px;
        display: block;

        .list-item {
            padding: 18px 25px;
            width: 208px;
            // height: 90px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #D8DAE5;
            margin-bottom: 11px;
            box-sizing: border-box;

            .icon {
                width: 32px;
                height: 32px;
            }

            .label {
                margin-top: 8px;
                font-family: Montserrat, Montserrat;
                font-weight: 600;
                font-size: 13px;
                color: #00234B;
                line-height: 19px;
                text-align: left;
                font-style: normal;
            }
        }

        .list-item:last-child {
            margin-bottom: 0;
        }

        .list-item.actived {
            background: #F4F6FA;
            border-radius: 4px 4px 0px 0px;
            border: 1px solid #F4F6FA;
            border-bottom: 2px solid #AC9455;
        }
    }

    .details {
        margin-left: 48px;
        width: 568px;

        // flex: 1;
        .title {
            // width: 568px;
            font-family: Montserrat, Montserrat;
            font-weight: 600;
            font-size: 19px;
            color: #1D1E25;
            line-height: 28px;
            text-align: left;

            // word-wrap: break-word;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 1;
        }

        .desc {
            // width: 568px;
            margin-top: 12px;
            font-family: Montserrat, Montserrat;
            font-weight: 500;
            font-size: 11px;
            color: #1D1E25;
            line-height: 17px;
            text-align: left;
            font-style: normal;

            // word-wrap: break-word;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 2;
        }

        .image {
            // width: 568px;
            // height: 409px;
            width: 100%;
            border-radius: 14px;
            margin-top: 11px;
        }
    }
}

.payment-experience-wrapper {
    .section-title {
        margin-top: 81px;
    }
}

.experience-cards-warpper {
    position: relative;
    margin: 0 auto;
    margin-top: 73px;
    width: 825px;
    
    .arrow-btn {
        position: absolute;
        top: 50%;
        margin-top: -17px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.06);
        // border-radius: 19px 19px 19px 19px;
    }

    .arrow-left-btn {
        left: -61px;
    }

    .arrow-right-btn {
        right: -61px;
    }
}

.experience-cards {
    .block {}
}

.experience-card-item {
    background-image: url('~@/assets/imgs/bg-redefine-payment-experience.png');
    background-size: 255px 100%;
    background-repeat: no-repeat;
    width: 255px;
    height: 182px;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;

    .icon {
        margin: 35px auto 0 auto;
        width: 29px;
        height: 29px;
    }

    .title {
        margin-top: 14px;
        font-family: Montserrat, Montserrat;
        font-weight: 600;
        font-size: 19px;
        color: #101840;
        line-height: 24px;
        text-align: center;
    }

    .desc {
        margin: 4px;
        font-family: ABeeZee, ABeeZee;
        font-weight: 400;
        font-size: 12px;
        color: #617798;
        line-height: 14px;
        text-align: center;
    }
}

.el-carousel__item {
    display: flex;
    justify-content: space-between;
}

// .el-carousel__item h3 {
//     color: #475669;
//     font-size: 14px;
//     opacity: 0.75;
//     line-height: 150px;
//     margin: 0;
// }

// .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n+1) {
//     background-color: #d3dce6;
// }

// ::v-deep .el-carousel__arrow {
//     background-color: #FFFFFF;
// }

@media screen and (max-width: 767px) {
    .section {
        padding-top: 17px;
        padding-bottom: 17px;
    }

    .section-title {
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 17px;
        color: #101840;
        line-height: 1.2;
        text-align: center;
        font-style: normal;
        // margin-top: 24px;
    }
    .payment-experience-wrapper {
    .section-title {
        margin-top: 26px;
    }
}
    .use-case-panel {
        margin-top: 24px;
        display: block;

        .list {
            width: 100%;
            white-space: nowrap;
            // display: flex;
            // flex-wrap: nowrap;
            overflow-x: scroll;
            padding-bottom: 24px;

            .list-item {
                display: inline-block;
                // flex: 1;
                margin: 0 9px;
                // display: block;
                padding: 9px 12px;
                // width: 150px;
                // height: 90px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #D8DAE5;
                // margin-bottom: 11px;
                box-sizing: border-box;

                .icon {
                    width: 24px;
                    height: 24px;
                    margin: 0 auto;
                }

                .label {
                    margin-top: 8px;
                    font-family: Montserrat, Montserrat;
                    font-weight: 600;
                    font-size: 13px;
                    color: #00234B;
                    line-height: 1.2;
                    text-align: left;
                    font-style: normal;
                }
            }

            .list-item:last-child {
                // margin-bottom: 0;
            }

            .list-item.actived {
                background: #F4F6FA;
                border-radius: 4px 4px 0px 0px;
                border: 1px solid #F4F6FA;
                border-bottom: 2px solid #AC9455;
            }
        }

        .details {
            box-sizing: border-box;
            margin-left: 0px;
            width: 100%;
            padding: 14px;

            // flex: 1;
            .title {
                // width: 568px;
                font-family: Montserrat, Montserrat;
                font-weight: 600;
                font-size: 16px;
                color: #1D1E25;
                line-height: 1.3;
                text-align: left;
                font-style: normal;

                // word-wrap: break-word;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp: 2;
            }

            .desc {
                // width: 568px;
                margin-top: 12px;
                font-family: Montserrat, Montserrat;
                font-weight: 500;
                font-size: 11px;
                color: #1D1E25;
                line-height: 14px;
                text-align: left;
                font-style: normal;

                // word-wrap: break-word;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp: 3;
            }

            .image {
                // width: 568px;
                // height: 409px;
                width: 100%;
                border-radius: 14px;
                margin-top: 11px;
            }
        }
    }

    .experience-cards-warpper {
        position: relative;
        width: calc(100% - 60px);
        margin: 0 auto;
        // margin-top: 24px;

        .arrow-btn {
            position: absolute;
            top: 50%;
            margin-top: -16px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.06);
            // border-radius: 19px 19px 19px 19px;
        }

        .arrow-left-btn {
            left: -30px;
        }

        .arrow-right-btn {
            right: -30px;
        }
    }

    .experience-cards {
        .block {}

        .experience-cards-panel {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // margin: 0 -18px;
        }
    }

    .experience-card-item {
        background-image: url('~@/assets/imgs/bg-redefine-payment-experience.png');
        background-size: 100% 100%;
        width: 145px;
        height: 104px;
        display: flex;
        background-repeat: no-repeat;
        flex-direction: column;
        align-items: center;
        margin: 0;
        margin-top: 25px;
        // margin-right: 38px;

        .icon {
            margin: 17px auto 0 auto;
            width: 17px;
            height: 17px;
        }

        .title {
            margin-top: 8px;
            font-family: Montserrat, Montserrat;
            font-weight: 600;
            font-size: 11px;
            color: #101840;
            line-height: 14px;
            text-align: center;
            // height: 24px;
        }

        .desc {
            margin: 0 5px;
            margin-top: 5px;
            font-family: ABeeZee, ABeeZee;
            font-weight: 400;
            font-size: 5px;
            color: #617798;
            line-height: 8px;
            text-align: center;
        }
    }

    .el-carousel__item {
        display: flex;
        justify-content: space-between;
    }
}
</style>