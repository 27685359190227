<template>
  <div class="home section">
    <!-- <img src="@/assets/imgs/image-get-app-banner.png" class="banner-block"/> -->
    <GetAppBannerSection />
    <CardIntroduction />
    <LearnMoreComp />
    <UseCaseComp />
    <VVIPAssetsCardComp />
    <QuestionComp />
  </div>
</template>

<script>
// @ is an alias to /src
import GetAppBannerSection from '@/components/GetAppBannerSection.vue'
import CardIntroduction from '@/components/home/CardIntroduction.vue'
import LearnMoreComp from '@/components/home/LearnMoreComp.vue'
import UseCaseComp from '@/components/home/UseCaseComp.vue'
import VVIPAssetsCardComp from '@/components/home/VVIPAssetsCardComp.vue'
import QuestionComp from '@/components/home/QuestionComp.vue'
export default {
  name: 'HomeView',
  components: {
    GetAppBannerSection,
    CardIntroduction,
    LearnMoreComp,
    UseCaseComp,
    VVIPAssetsCardComp,
    QuestionComp
  }
}
</script>
<style lang="scss" scoped>
.banner-block {
  width: 100%
}

.banner-content {
  width: 996px;
  margin: 0 auto;
  padding-top: 154px;

  .title {
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 57px;
    text-align: left;
    font-style: normal;
  }

  .sub-title {
    margin-top: 75px;
    font-family: Montserrat, Montserrat;
    font-weight: 800;
    font-size: 13px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: justified;
    font-style: normal;
  }

  .get-app-btn {
    margin-top: 18px;
    width: 142px;
    height: 48px;
    background: #AC9455;
    border-radius: 37px 37px 37px 37px;
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 23px;
    text-align: center;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
