<template>
  <div id="app">
    <NavBar/>
    <router-view v-if="isRouterAlive"></router-view>
    <FooterComp/>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import FooterComp from '@/components/FooterComp.vue'
const WIDTH = 992 // refer to Bootstrap's responsive design
const { body } = document
import store from '@/store'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
      Path: '',
    }
  },
  components:{
    NavBar,
    FooterComp
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  watch: {
    $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        store.dispatch('app/closeSideBar', { withoutAnimation: false })
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    const isMobile = this.$_isMobile()
    console.log('isMobile:',isMobile)
    if (isMobile) {
      store.dispatch('app/toggleDevice', 'mobile')
      store.dispatch('app/closeSideBar', { withoutAnimation: true })
    }
  },
  created() {
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },

    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        console.log("isMobile: " + isMobile)
        store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')

        if (isMobile) {
          store.dispatch('app/closeSideBar', { withoutAnimation: true })
        }
      }
    }
  },
}
</script>

<style lang="scss">
body{
  margin: 0;
  padding: 0;
  line-height: 1;
}
#app {
  min-width: 1366px;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1365px) {
  #app{
    min-width: unset;
    width: 100%;
  }
}
</style>
