export default {
  vabI18n: {
    菜单项_首页: "Home",
    菜单项_支付: "Payment",
    菜单项_转账: "Transfer",
    菜单项_更多: "Learn More",
    菜单项_关于我们: "About Us",
    菜单项_联系我们: "Contact Us",
    菜单项_卡片: "Cards",
    菜单项_下载: "Download",
    文本_首家颠覆全球金融生态的线上数字银行: `FIRST ON-CHAIN DIGITAL BANK REVOLUTIONIZING THE GLOBAL FINANCIAL ECOSYSTEM`,
    文案_首家颠覆全球金融生态的线上数字银行介绍: "Our platform seamlessly integrates cryptocurrency and traditional financial management,  breaking down barriers between crypto and fiat currency exchange.\nExperience FAST global payments and COMPREHENSIVE FINANCIAL SOLUTIONS all in  one place.",
    文本_获取APP: "Get the app",
    文本_探索高度定制的卡解决方案: "DISCOVER THE HIGHLY TAILORABLE CARD  SOLUTIONS",
    文案_探索高度定制的卡解决方案: "Enhance your financial operations with the MW Card,  a versatile and customizable embedded card program.  MW Card empowers you to move beyond traditional  payment processes, unlock new revenue  opportunities, and modernize your payment  experience, providing greater flexibility, control, and  mobility.",
    文本_即时交易: "Instant transaction",
    文本_广受欢迎: "Widely-accepeted",
    文本_获取银行卡: "Get your card",
    标签选项卡_虚拟卡: "VIRTUAL CARD",
    标签选项卡_实体卡: "PHYSICAL CARD",
    文本_面向员工供应商和客户的一体化支付卡: "ALL-IN-ONE PAYMENT CARDS FOR YOUR<br><span style='color: #AC9455;'>EMPLOYEES,</span> <span style='color: #AC9455;'>VENDORS,</span> AND <span style='color: #AC9455;'>CUSTOMERS </span>",
    文本_联名卡计划: "Co-Branded Card Program",
    文案_联名卡计划介绍: "Issue branded Physical and Virtual cards For Your Company or Your Customers In  Just A Few Weeks, With Your Logo On Pre-Approved Card Designs",
    文本_白标卡计划: "White Label Card Program",
    文案_白标卡计划介绍: "A Fully-Functional Suite of Card Programs, Allowing You To Launch With Your Own  Card, Web Portal And Mobile App. Customise The Fee Table To Suit Your Needs.",
    文本_发卡解决方案: "Card-Issuing Solutions",
    文案_发卡解决方案介绍: "Manage The Entire Card Issuing Process And Fully Integrate The Card Management  Lifecycle. Benefit From Robust Transaction And Revenue Reporting Functionalities  For Comprehensive Oversight And Control.",
    文本_定制化产品: "Customizable Products",
    文案_定制化产品介绍: "Manage The Entire Card Issuing Process And Fully Integrate The Card Management  Lifecycle. Benefit From Robust Transaction And Revenue Reporting Functionalities  For Comprehensive Oversight And Control.",
    文本_放开分散支付的权力: "UNLEASED THE POWER OF DECENTRALIZED PAYMENT",
    文本_非托管资产卡与IBAN: "NON-CUSTODIAL ASSET CARD AND IBAN",
    文本_你的财富由你控制: "Your Money, Your Control",
    文本_了解更多: "Learn More",
    文本_标题_用例: "USE CASES",
    标签选项卡_消费者: "CONSUMERS",
    标签选项卡_工资单: "PAYROLL",
    标签选项卡_多币种: "MULTI-CURRENCIES",
    标签选项卡_旅行: "TRAVEL",
    标签选项卡_学生: "STUDENT",
    文本_为用户提供预付卡: "Prepaid Cards For Your Users",
    文案_为用户提供预付卡介绍: "Enhance Your Business By Offering Your Clients Branded Prepaid Cards. Improve Loyalty And Unlock New Powerful Revenue Streams For Your Business.",
    文本_在公司工资卡上支付工资: "Pay Salary On Your Company's Payroll Cards",
    文案_在公司工资卡上支付工资介绍: "Simplify Your Payroll And Save On Costs With Our Branded Cards. Even Paychecks Can Now Be Directly Credited In The Cards.",
    文本_一卡多币: "Multiple Currencies On One Card",
    文案_一卡多币介绍: "Our Multi-Currency Prepaid Card And Auto-Conversion At The Time Of Transaction Provide The Best Value. You May Also Earn Revenue From Forex.",
    文本_随时随地轻松支付: "Pay With The Ease At Any Time And Everywhere",
    文案_随时随地轻松支付介绍: "Prepaid Travel Cards Serve As A Convenient Alternative To Cash And Traveler's Cheques. Transact Securely Between Time Zones Around The World.",
    文本_面向年轻一代的预付卡解决方案: "Prepaid Card Solutions For The Young Generations",
    文案_面向年轻一代的预付卡解决方案介绍: "Prepaid Cards Offer Students An Easy Solution For Managing The Funds Received From Parents Or Employment, And Using Them On Educational Expenses  And Pos/Ecommerce Transactions.",

    文本_重新定义支付体验: "REDEFINE PAYMENT EXPERIENCE",

    文本_支付体验_自托管: "Self-hosted",
    文案_支付体验_自托管介绍: "Users are asset custodians, manage and control their assets",
    文本_支付体验_快捷方便: "Fast and convenient",
    文案_支付体验_快捷方便介绍: "Global two-way settlement within 5 minutes",
    文本_支付体验_可信安全: "Trusted Security",
    文案_支付体验_可信安全介绍: "Complies with top PCI-DSS security standards",
    文本_支付体验_灵活性: "Flexiblity",
    文案_支付体验_灵活性介绍: "Easy Management, Deposit & Withdrawal at any time",

    文本_MW_VVIP钛资产卡象征尊严: "MW VVIP TITANIUM ASSET CARD SYMBOL OF DIGNITY",
    文案_MW_VVIP钛资产卡象征尊严介绍: "MW VVIP Titanium Asset Card - Exclusively designed to embody  wealth and elevate your distinguished status.",

    文本_VVIP卡尊严_亚太地区唯一代表: "The only representative in Asia Pacific",
    文案_VVIP卡尊严_亚太地区唯一代表介绍: "MW Global is the only company that cooperates in issuing MasterCard asset cards",
    文本_VVIP卡尊严_全球可用: "Available worldwide",
    文案_VVIP卡尊严_全球可用介绍: "You can use it with confidence at merchants, restaurants, and service providers around the world and enjoy a seamless payment experience.",
    文本_VVIP卡尊严_享受交易: "Enjoy trading",
    文案_VVIP卡尊严_享受交易介绍: "MW asset card brings you real consumption freedom and allows you to experience truly unlimited payment freedom.",
    文本_VVIP卡尊严_PINGO是跨境电商平台的首选: "PINGO is the first choice for cross-border e-commerce platforms",
    文案_VVIP卡尊严_PINGO是跨境电商平台的首选介绍: "VVIP可享受PINGO is the first choice for cross-border e-commerce platforms高达10%的购物积分折扣",

    标题_常见问题: "FREQUENTLY ASKED QUESTIONS",
    副标题_将法币与您的加密货币转移给世界上任何地方的任何人: "Transfer fiat with your crypto to anyone anywhere in the world",
    标题_机会: "OPPORTUNITIES",
    副标题_机会介绍: "The ultimate solution for spending digital currencies.  With MW, you can enjoy seamless transactions and earn  up to 8% annual return for digital currencies staking, and  stay in control of your card's security.",
    文本_加入MW: "Join MW",

    文本_首页_常见问题_MW是否支持加密货币存款: " Does MW support deposit of crypto currency?",
    文本_首页_常见问题_MW是否支持加密货币存款_回答: "MW supports the deposit of USDT, BTC, and ETH. Users can top up their Mastercard anytime and anywhere according to their needs and consumption, and the whole process does not take more than 10 minutes; MW will open to more cryptocurrencies in the second phase.",

    标题_您的一体化加密支付解决方案: "YOUR All-IN-ONE CRYPTO\n PAYMENT SOLUTION",
    文本_可访问的加密钱包每个人无处不在让我们通过MWGlobal拥抱加密货币支付的未来: " Accessible crypto wallet for everyone, everywhere.\n Let’s embrace the future with crypto currency payments via MW Global!",
    文本_立即办卡: "Get your card now",
    标题_像法币一样使用加密货币: "SPEND CRYPTO LIKE FIAT",
    文本_无缝管理你的日常开支: "Manage your daily expenses seamlessly",
    标题_虚拟卡: "VIRTUAL CARD",
    文本_极具竞争力: "Super competitive rate",
    文本_即时执行: "Instant Execution",
    标题_实体卡: "PHYSICAL CARD",
    文本_点击支付: "Tap and Pay",
    文本_实时交易: "Real-time transaction",
    文本_自动取款机取款: "ATM withdrawal",
    标题_随处使用加密货币: "SPEND CRYPTO EVERYWHERE",
    文本_将法币与您的加密货币转移给世界上任何地方的任何人: "Transfer fiat with your crypto to anyone anywhere in the world",
    标题_我们的产品公平的利率没有隐藏费用: "Our Product\nFair Rates. No Hidden Fees.",
    文本_发现每个计划的申请费和充值费找到你的完美匹配: "Discover each plan's application fees and top-up fee, to find your perfect match.",
    文本_实体卡费用: "Physical card fees $100",
    文本_虚拟卡费用: "Virtual Card Fees $10",
    了解更多: "LEARM MORE",
    文本_世界是你的: "THE WORLD IS YOURS",
    文本_MWGLOBAL数字银行多年来运营良好接受全球企业和用户托管资金33亿美元托管资金在全球数字银行中排名第一: "MW GLOBAL digital bank has been operating well for many years, accepting custodial funds of USD 3.3 billion from enterprises and users worldwide, ranking first among global digital banks in terms of custodial funds.",
    文本_你的财产在我们这里很安全: "Your assets are safe with us",
    文本_连接钱包: "Connect your Wallet",
    文本_托管资金用于币安等世界知名平台利润返还给用户月平均收益率: "The custodial funds are used on world-renowned platforms such as Binance, and the profits are returned to users, with an average monthly yield of 15%~18%.",

    文本_在舒适的家中体验无与伦比的数字便利: " Experience Unmatched Digital Convenience from the Comfort of Your Home",
    文本_发现为您的旅行餐饮网上购物和日常需求量身定制的独家优惠通过无缝的数字解决方案来改善您的生活方式节省时间金钱和精力: "Discover exclusive offers tailored to your travel, dining, online shopping, and everyday needs. Save time, money, and effort with seamless digital solutions designed to enhance your lifestyle.",
    文本_为互联世界提供无缝传输: "SEAMLESS TRANSFERS FOR A CONNECTED WORLD",
    文本_轻松管理内部本地和国际银行转账体验安全高效的交易让您与全球保持联系: "Effortlessly manage internal, local, and international bank transfers. Experience secure and efficient transactions, keeping you connected globally.",
    文本_全大写_本地银行转帐: "LOCAL BANK TRANSFER",
    文本_全大写_内部转账: "INTERNAL TRANSFER",
    文本_全大写_国际银行转帐: "INTERNATIONAL BANK TRANSFER",
    文本_即将到来: "COMING SOON",
    文本_全大写_快速安全实惠即时接收转账: "SWIFT,SECURE,AFFOR DABLE;\nINSTANTLY RECEIVE TRANSFERS",
    文本_即时以低费用接收全球转账确保快速和成本有效的交易: "Instantly receive transfers worldwide with low fees, ensuring swift and cost effective transactions",

    文本_全大写_通过分散的解决方案和无缝的数字资产增长来释放全球金融: "UNLESHING GLOBAL FINANCE TTHROUGH DECENTRALIZED SOLUTIONS AND SEAMLESS DIGITAL ASSET GROWTH",
    文本_分散的金融: "Decentralized Finance",
    文本_数字资产增长: "Digital Asset Growth √",
    文本_全球跨境支付与结算: "Global Cross-Border Payment & Settlement",
    文本_数字法定货币结算: "Digital – Fiat Currency Settlement",

    我们正在让每个人都能使用加密货币: "WE ARE MAKING CRYPTOCURRENCIES ACCESSIBLE FOR EVERYONE",
    我们的愿景: "OUR VISION",
    我们致力于改变人们的支付方式通过区块链技术和数字资产我们正在构建一个数字资产支付基础设施重点是促进更大的金融包容性和加密货币的经济机会: "We are dedicated to transforming the way people make payments. Through the blockchain technology and digital assets, we are building a digital asset payment infrastructure focusing on promoting greater financial inclusion and economic opportunities of cryptocurrencies.",
    加密先锋: "Crypto Pioneers",
    实现加密货币与法定货币的无缝交易实现Web30的无现金时代: "Realize seamless transactions between cryptocurrency and fiat currency to realize the cashless era of Web3.0.",
    数字支付革命: "Digital Payment Revolution",
    我们的目标是将加密货币的效用传播给每个人因为我们相信支付的未来在于数字化我们很自豪能够使这一未来成为现实: "We aim to spread the utility of cryptocurrency to everyone as we believe that the future of payment lies in digitalization, and we are proud to make this future a reality",
    价值: "Values",
    与合作伙伴共同构建创新透明安全高效的支付系统: "Build an innovative, transparent, secure and efficient payment system with partners.",

    文本_与合作伙伴共同构建创新透明安全高效的支付系统: " Work with MW Global Empowering Your Transactions:  Navigating the Future of  Payments Together",
    文本_想成为我们的合作伙伴吗: "Want to become our partners??",
    文本_请给我们一些你自己的详情我们会很快联系: "Please give us a little details of yourself and we will be in touch soon!",
    文本_对我们的产品有问题吗: "Got questions about our products??",
    文本_网站待定: "Website to be determined",
    文本_电子邮件地址待定: "Email address pending",
    文本_联系我们表单_接受新品提醒: "If you do not wish to receive marketing and promotional materials, please check the box.",
    文本_提交: "Submit",

    文本_表单_联系我们_字段名_姓: "Last name",
    文本_表单_联系我们_字段名_名: "First name",
    文本_表单_联系我们_字段名_电子邮箱: "Email",
    文本_表单_联系我们_字段名_手机号码: "Phone number ",
    文本_表单_联系我们_字段名_公司名称: "Company name",
    文本_表单_联系我们_字段名_公司网站: "Company website",
    文本_表单_联系我们_字段名_合作类型: "Collaboration Type",
    文本_表单_联系我们_字段名_留言: "Message",
    文本_表单_联系我们_输入提示_姓: "Enter your name",
    文本_表单_联系我们_输入提示_名: "Enter your name",
    文本_表单_联系我们_输入提示_电子邮箱: "Enter your work email",
    文本_表单_联系我们_输入提示_手机号码: "Enter your phone number",
    文本_表单_联系我们_输入提示_公司名称: "Enter company name",
    文本_表单_联系我们_输入提示_公司网站: "Enter Company website",
    文本_表单_联系我们_输入提示_合作类型: "What are you interestsd in?",
    文本_表单_联系我们_输入提示_留言: "How can we help you?",
    提示_表单_联系我们_提交成功: "Successful submission, we will contact you as soon as possible",

    文本_现在开始您的加密支付之旅与mw全球:"START YOUR CRYPTO PAYMENT  JOURNEY WITH MW GLOBAL NOW",
    文本_下载页_下载APP:"Download on the",
    文本_下载页_获取:"Get in on",

    文本_文本内容: "文本内容",
    文案_文本内容介绍: "文案内容",
  },
}
