<template>
    <div class="section section-learn-more" style="">
        <div class="wrapper">
            <div class="learn-more-block">
                <div class="title">
                    {{ translateTitle('文本_放开分散支付的权力') }}
                </div>
                <div class="sub-title">
                    {{ translateTitle('文本_非托管资产卡与IBAN') }}
                </div>
                <div class="text">
                    {{ translateTitle('文本_你的财富由你控制') }}
                </div>
                <div class="learn-more-btn" @click="$pu.redirectCustomLoginPage()">
                    {{ translateTitle('文本_了解更多') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LearnMoreComp',
    components: {
    },
    // mixins: [ResizeMixin],
    computed: {
        // sidebar() {
        //   return this.$store.state.app.sidebar
        // }
    },
    // props: {
    //   arrayProp: {
    //     type: Array,
    //     required: false,
    //     default: function () {
    //       return []
    //     }
    //   },
    //   arrayProp: {
    //     type: Object,
    //     required: false,
    //     default: function () {
    //       return {}
    //     }
    //   }
    // },
    data() {
        const preData = {}
        return {
            message: 'Hello Vue!'
        };
    },
    watch: {
        message: {
            handler: function (newVal, oldVal) {

            },
            //立即监听
            immediate: true,
            //深度监听
            deep: false
        }
    },
    created() {
       // console.log('created: 组件实例创建完成');
    },
    mounted() {
        // console.log('mounted: 组件挂载到DOM之后');
    },
    methods: {

    },
    updated() {
        // console.log('updated: 组件数据更新之后');
    },
    beforeDestroy() {
        //console.log('beforeDestroy: 组件实例销毁之前');
    }
};
</script>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.section-learn-more {
    height: 439x;
    background-color: #F4F6FA;
    background-image: url('~@/assets/imgs/bg-learn-more.png');
    background-position: top right;
    background-size: 621px 439px;
    background-repeat: no-repeat;
    position: relative;
}

.learn-more-block {
    position: relative;

    padding: 58px 0;

    .title {
        // padding-top: 58px;
        width: 420px;
        // height: 101px;
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 28px;
        color: #101010;
        line-height: 51px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        white-space: pre-line;
    }

    .sub-title {
        margin-top: 39px;
        width: 326px;
        // height: 68px;
        font-family: Montserrat, Montserrat;
        font-weight: 600;
        font-size: 19px;
        color: #1D1E25;
        line-height: 34px;
        text-align: left;
        font-style: normal;
        white-space: pre-line;
    }

    .text {
        margin-top: 25px;
        // width: 168px;
        // height: 18px;
        width: 326px;
        font-family: Poppins, Poppins;
        font-weight: 600;
        font-size: 14px;
        color: #1D1E25;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        white-space: pre-line;
    }

    .learn-more-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        width: 112px;
        height: 37px;
        background: #AC9455;
        border-radius: 47px 47px 47px 47px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 18px;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {

    .section-learn-more {
        height: auto;
        background-color: #F4F6FA;
        background-image: url('~@/assets/imgs/bg-learn-more.png');
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 14px;
        position: relative;
    }

    .learn-more-block {
        position: relative;
        padding: 0;

        .title {
            margin-top: 24px;
            width: 175px;
            height: 66px;
            font-family: Montserrat, Montserrat;
            font-weight: bold;
            font-size: 14px;
            color: #101010;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }

        .sub-title {
            margin-top: 10px;
            // width: 187px;
            // height: 14px;
            font-family: Montserrat, Montserrat;
            font-weight: 600;
            font-size: 12px;
            color: #1D1E25;
            line-height: 14px;
            text-align: left;
            font-style: normal;
        }

        .text {
            margin-top: 10px;
            // width: 81px;
            font-family: Poppins, Poppins;
            font-weight: 600;
            font-size: 10px;
            color: #1D1E25;
            line-height: 9px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }

        .learn-more-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            width: 120px;
            height: 35px;
            background: #AC9455;
            border-radius: 57px 57px 57px 57px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 12px;
            text-align: center;
            font-style: normal;
        }
    }
}
</style>