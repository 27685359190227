import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// Vue.use(ElementUI, {
//   size: "medium", // set element-ui default size设置元素默认大小
//   i18n: (key, value) => i18n.t(key, value), // 在注册Element时设置i18n的处理方法
// });
Vue.use(ElementUI)

//全局样式
import '@/styles/font.css'
import '@/styles/index.scss' // global css
import '@/styles/page.scss' // global css
import '@/styles/reset.scss' // global css

import i18n from "./i18n"; // Internationalization
Vue.use(ElementUI, {
  size: "medium", // set element-ui default size设置元素默认大小
  i18n: (key, value) => i18n.t(key, value), // 在注册Element时设置i18n的处理方法
});

import mixins from "./mixin/index";
Vue.mixin(mixins);

//通用工具类
import commonUtils from '@/utils/commonUtil'
Vue.prototype.$cu = commonUtils

import pageUtil from '@/utils/pageUtil'
Vue.prototype.$pu = pageUtil

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
