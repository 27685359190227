<template>
    <div class="section vvip-card-section" style="">
        <div class="wrapper">
            <div class="section-title">
                {{ translateTitle('文本_MW_VVIP钛资产卡象征尊严') }}
            </div>
            <div class="sub-title">
                {{ translateTitle('文案_MW_VVIP钛资产卡象征尊严介绍') }}
            </div>
            <div v-if="device === 'desktop'" class="flow-card-pos" style="">
                <div class="white-round-card lt">
                    <div class="title">
                        {{ translateTitle('文本_VVIP卡尊严_亚太地区唯一代表') }}
                    </div>
                    <div class="content">
                        {{ translateTitle('文案_VVIP卡尊严_亚太地区唯一代表介绍') }}
                    </div>
                </div>
                <img class="middle" style="" src="@/assets/imgs/image-vvip-asset-caard.png">
                <div class="white-round-card rt">
                    <div class="title">
                        {{ translateTitle('文本_VVIP卡尊严_全球可用') }}
                    </div>
                    <div class="content">
                        {{ translateTitle('文案_VVIP卡尊严_全球可用介绍') }}
                    </div>
                </div>
                <div class="white-round-card bl">
                    <div class="title">
                        {{ translateTitle('文本_VVIP卡尊严_享受交易') }}
                    </div>
                    <div class="content">
                        {{ translateTitle('文案_VVIP卡尊严_享受交易介绍') }}
                    </div>
                </div>
                <div class="white-round-card br">
                    <div class="title">
                        {{ translateTitle('文本_VVIP卡尊严_PINGO是跨境电商平台的首选') }}
                    </div>
                    <div class="content">
                        {{ translateTitle('文案_VVIP卡尊严_PINGO是跨境电商平台的首选介绍') }}
                    </div>
                </div>
            </div>

            <div v-else class="flow-card-pos" style="">
                <img class="middle" style="" src="@/assets/imgs/image-vvip-asset-caard.png">

                <div class="white-round-card lt">
                    <div class="title">
                        {{ translateTitle('文本_VVIP卡尊严_亚太地区唯一代表') }}
                    </div>
                    <div class="content">
                        {{ translateTitle('文案_VVIP卡尊严_亚太地区唯一代表介绍') }}
                    </div>
                </div>
                <div class="white-round-card rt">
                    <div class="title">
                        {{ translateTitle('文本_VVIP卡尊严_全球可用') }}
                    </div>
                    <div class="content">
                        {{ translateTitle('文案_VVIP卡尊严_全球可用介绍') }}
                    </div>
                </div>
                <div class="white-round-card bl">
                    <div class="title">
                        {{ translateTitle('文本_VVIP卡尊严_享受交易') }}
                    </div>
                    <div class="content">
                        {{ translateTitle('文案_VVIP卡尊严_享受交易介绍') }}
                    </div>
                </div>
                <div class="white-round-card br">
                    <div class="title">
                        {{ translateTitle('文本_VVIP卡尊严_PINGO是跨境电商平台的首选') }}
                    </div>
                    <div class="content">
                        {{ translateTitle('文案_VVIP卡尊严_PINGO是跨境电商平台的首选介绍') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VVIPAssetsCardComp',
    components: {
    },
    // mixins: [ResizeMixin],
    computed: {
        device() {
            return this.$store.state.app.device
        }
    },
    // props: {
    //   arrayProp: {
    //     type: Array,
    //     required: false,
    //     default: function () {
    //       return []
    //     }
    //   },
    //   arrayProp: {
    //     type: Object,
    //     required: false,
    //     default: function () {
    //       return {}
    //     }
    //   }
    // },
    data() {
        const preData = {}
        return {
            message: 'Hello Vue!'
        };
    },
    watch: {
        message: {
            handler: function (newVal, oldVal) {

            },
            //立即监听
            immediate: true,
            //深度监听
            deep: false
        }
    },
    created() {
       // console.log('created: 组件实例创建完成');
    },
    mounted() {
        // console.log('mounted: 组件挂载到DOM之后');
    },
    methods: {

    },
    updated() {
        // console.log('updated: 组件数据更新之后');
    },
    beforeDestroy() {
        //console.log('beforeDestroy: 组件实例销毁之前');
    }
};
</script>
<style lang="scss" scoped>
.section {
    background: #F4F6FA;
    padding: 44px 0;
}

.vvip-card-section {
    padding: 44px 0 0px 0;
}

.section-title {
    margin: 0 auto;
    width: 500px;
    height: 81px;
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 28px;
    color: #153C3C;
    line-height: 47px;
    text-align: center;
}

.sub-title {
    margin: 0 auto;
    margin-top: 42px;
    width: 620px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 11px;
    color: #1D1B1B;
    line-height: 16px;
    text-align: center;
}

.white-round-card {
    width: 272px;
    // height: 124px;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px 12px 12px 12px;
    padding: 21px 16px;
    box-sizing: border-box;
    margin-bottom: 12px;

    .title {
        font-family: Montserrat, Montserrat;
        font-weight: 600;
        font-size: 13px;
        color: #1D1E25;
        line-height: 20px;
        text-align: left;
        font-style: normal;
    }

    .content {
        margin-top: 11px;
        font-family: Montserrat, Montserrat;
        font-weight: normal;
        font-size: 11px;
        color: #617798;
        line-height: 16px;
        text-align: left;
    }
}

.flow-card-pos {
    margin: 0 auto;
    margin-top: 55px;
    width: 803px;
    height: 399px;
    position: relative;
    display: block;

    // overflow: hidden;
    .lt {
        position: absolute;
        left: 0;
        top: 0;
        // float: left;
    }

    .rt {
        position: absolute;
        top: 35px;
        left: 532px;

        // float: left;
        // margin-top: 35px;
        // margin-left: 24px;
    }

    .middle {
        // float: left;
        // margin-top: 59px;
        // margin-left: 15px;
        width: 219px;
        height: 138px;
        position: absolute;
        top: 59px;
        left: 288px;
        // margin-top: -69px;
        // margin-left: -109px;
    }

    .bl {
        position: absolute;
        left: 26px;
        top: 203px;
    }

    .br {
        position: absolute;
        top: 210px;
        left: 527px;
    }
}

@media screen and (max-width: 767px) {
    * {
        box-sizing: border-box;
    }

    .section {
        background: #F4F6FA;
        padding: 22px 22px;
    }

    .vvip-card-section {
        padding: 22px 0;
    }

    .section-title {
        width: auto;
        height: auto;
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 17px;
        color: #153C3C;
        line-height: 28px;
        text-align: center;
        font-style: normal;
        margin: 0 12px;
    }

    .sub-title {
        margin: 0 12px;
        width: auto;
        margin-top: 13px;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        font-size: 11px;
        color: #1D1B1B;
        line-height: 20px;
        text-align: center;
        font-style: normal;
    }

    .white-round-card {
        width: 155px;
        // margin: 12px;
        height: unset;
        background: #FFFFFF;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
        border-radius: 12px 12px 12px 12px;
        padding: 11px 9px;
        box-sizing: border-box;

        .title {
            font-family: Montserrat, Montserrat;
            font-weight: 600;
            font-size: 6px;
            color: #1D1E25;
            line-height: 9px;
            text-align: left;
            font-style: normal;
        }

        .content {
            margin-top: 6px;
            font-family: Montserrat, Montserrat;
            font-weight: normal;
            font-size: 5px;
            color: #617798;
            line-height: 9px;
            text-align: left;
            font-style: normal;
        }
    }

    .flow-card-pos {
        width: auto;
        height: unset;
        overflow: hidden;
        // margin: 0 -22px;
        // padding: 0 22px;
        margin-top: 43px;
        // position: relative;

        .lt {
            position: unset;
            float: left;
            margin-left: 15px
        }

        .rt {
            position: unset;
            float: left;
            margin-top: 11px;
            margin-left: 19px
        }

        .middle {
            position: unset;
            width: 187px;
            height: 117px;
            margin: 0 auto;
            // margin-top: 43px;
            margin-bottom: 24px;
            display: block;
        }

        .bl {
            position: unset;
            float: left;
            margin-left: 29px;
            // margin-top: 30px;
        }

        .br {
            position: unset;
            float: left;
            margin-top: 16px;
            margin-left: 19px
        }
    }
}
</style>