<template>
    <div class="section" style="">
        <div class="wrapper">
            <div class="section-title">
                {{ translateTitle('标题_常见问题') }}
            </div>
            <div class="sub-title">
                {{ translateTitle('副标题_将法币与您的加密货币转移给世界上任何地方的任何人') }}
            </div>
            <div style="margin-top: 20px;">
                <el-collapse v-model="activeName" accordion>
                    <div class="collapse-row" style="position: relative;">
                        <img class="collapse-plus-btn" src="@/assets/imgs/icon-high-light-plus-circle.png" />
                        <el-collapse-item :title="translateTitle('文本_首页_常见问题_MW是否支持加密货币存款')" name="1">
                            <div>
                                {{ translateTitle('文本_首页_常见问题_MW是否支持加密货币存款_回答') }}
                            </div>
                        </el-collapse-item>
                    </div>
                    <!-- <div class="collapse-row" style="position: relative;">
                        <img class="collapse-plus-btn" src="@/assets/imgs/icon-high-light-plus-circle.png" />
                        <el-collapse-item title="How to deposit cryptocurrencies?" name="2">
                            <div>
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                            </div>
                        </el-collapse-item>
                    </div>
                    <div class="collapse-row" style="position: relative;">
                        <img class="collapse-plus-btn" src="@/assets/imgs/icon-high-light-plus-circle.png" />
                        <el-collapse-item title="Why was my KYC verification not approved?" name="3">
                            <div>
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                                Why was my KYC verification not approved?
                            </div>
                        </el-collapse-item>
                    </div> -->
                </el-collapse>
            </div>

            <div class="section-title" style="margin-top: 27px;">
                {{ translateTitle('标题_机会') }}
            </div>
            <div class="section-sub-desc ">
                {{ translateTitle('副标题_机会介绍') }}
            </div>
            <div class="join-btn" style="margin-top: 27px;" @click="$pu.redirectCustomLoginPage()">
                {{ translateTitle('文本_加入MW') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComponentName',
    components: {
    },
    // mixins: [ResizeMixin],
    computed: {
        // sidebar() {
        //   return this.$store.state.app.sidebar
        // }
    },
    // props: {
    //   arrayProp: {
    //     type: Array,
    //     required: false,
    //     default: function () {
    //       return []
    //     }
    //   },
    //   arrayProp: {
    //     type: Object,
    //     required: false,
    //     default: function () {
    //       return {}
    //     }
    //   }
    // },
    data() {
        const preData = {}
        return {
            message: 'Hello Vue!',
            activeName: ''
        };
    },
    watch: {
        message: {
            handler: function (newVal, oldVal) {

            },
            //立即监听
            immediate: true,
            //深度监听
            deep: false
        }
    },
    created() {
       // console.log('created: 组件实例创建完成');
    },
    mounted() {
        // console.log('mounted: 组件挂载到DOM之后');
    },
    methods: {

    },
    updated() {
        // console.log('updated: 组件数据更新之后');
    },
    beforeDestroy() {
        //console.log('beforeDestroy: 组件实例销毁之前');
    }
};
</script>
<style lang="scss" scoped>
.el-collapse {
    border: 0;
}

.section {
    padding: 42px 0;
}

.section-title {
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 28px;
    color: #101840;
    line-height: 51px;
    text-align: center;
}

.sub-title {
    margin-top: 11px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: #1D1B1B;
    line-height: 18px;
    text-align: center;
    // width: 347px;
    // height: 23px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: #1D1B1B;
    line-height: 23px;
    text-align: justified;
    font-style: normal;
    text-transform: none;
}

.question-wrapper {
    margin-top: 11px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: #1D1B1B;
    line-height: 18px;
    text-align: center;
    width: 347px;
    height: 23px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 11px;
    color: #1D1B1B;
    line-height: 23px;
    text-align: justified;
    font-style: normal;
    text-transform: none;

    .sub-title {
        margin-top: 11px;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        font-size: 14px;
        color: #1D1B1B;
        line-height: 18px;
        text-align: center;
        width: 347px;
        height: 23px;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        font-size: 11px;
        color: #1D1B1B;
        line-height: 23px;
        text-align: justified;
        font-style: normal;
        text-transform: none;
    }
}

::v-deep .el-collapse-item__header {
    border: 0;
    height: 40px;

    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 12px;
    color: #1D1B1B;
    line-height: 23px;
}

::v-deep .el-collapse-item {
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 11px 11px;
}

::v-deep .el-collapse-item:last-child {
    margin-bottom: 0;
}

::v-deep .el-collapse-item__content {
    // padding: 0px 24px;
}

::v-deep .el-collapse-item__wrap {
    border: 0;
}

::v-deep .el-collapse-item__arrow {
    display: none;
}

::v-deep .el-collapse-item__content {
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 12px;
    color: #1D1B1B;
    line-height: 23px;
    text-align: justify;
    font-style: normal;
    padding-top: 12px;
    padding-bottom: 12px;
}

.section-sub-desc {
    margin-top: 22px;
    // width: 759px;
    // height: 34px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: #696F8C;
    line-height: 18px;
    // text-stroke: 1px #F5F5F5;
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px #F5F5F5;

    //     font-family: Montserrat, Montserrat;
    // font-weight: 400;
    // font-size: 9px;
    // color: #1D1B1B;
    // line-height: 18px;
    // text-align: justified;
}

.join-btn {
    width: 142px;
    height: 48px;
    background: #AC9455;
    border-radius: 37px 37px 37px 37px;
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 17px;
    text-align: center;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.collapse-plus-btn {
    position: absolute;
    right: 12px;
    top: 24px;
    width: 24px;
    height: 24px;
}

.collapse-row {

    margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
    * {
        box-sizing: border-box;
    }

    .section {
        padding: 21px 21px;

    }

    .section-title {
        // width: 81px;
        // height: 9px;
        font-family: Poppins, Poppins;
        font-weight: 600;
        font-size: 14px;
        color: #1D1E25;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        text-transform: none;
    }

    .sub-title {
        margin-top: 14px;
        // font-family: Montserrat, Montserrat;
        // font-weight: 400;
        // font-size: 12px;
        // color: #1D1B1B;
        // line-height: 14px;
        // text-align: center;
        // font-style: normal;
        // width: 81px;
        // height: 9px;
        font-family: Poppins, Poppins;
        font-weight: 600;
        font-size: 11px;
        color: #1D1E25;
        line-height: 14px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        color: #696F8C;
    }

    ::v-deep .el-collapse-item__header {
        height: 20px;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        font-size: 12px;
        color: #1D1B1B;
        line-height: 20px;
        // padding: 0 3px;
    }

    ::v-deep .el-collapse-item {
        margin-bottom: 16px;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
        padding: 11px 12px;
    }

    ::v-deep .el-collapse-item:last-child {
        margin-bottom: 0;
    }

    ::v-deep .el-collapse-item__content {
        // padding: 0px 24px;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        font-size: 12px;
        color: #1D1B1B;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    ::v-deep .el-collapse-item__wrap {
        border: 0;
        margin-top: 12px;
    }

    .section-sub-desc {
        margin-top: 9px;
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 11px;
        color: #696F8C;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 0px #F5F5F5;
    }

    .join-btn {
        width: 120px;
        height: 38px;
        background: #AC9455;
        border-radius: 37px 37px 37px 37px;
        font-family: Montserrat, Montserrat;
        font-weight: bold;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 17px;
        text-align: center;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .collapse-plus-btn {
        position: absolute;
        right: 12px;
        top: 17px;
        width: 12px;
        height: 12px;
    }
}
</style>